import React from "react"
import SEO from "../../components/seo"
import HeroFeaturePage from "../../components/pages/shared/hero/feature-page"
import Layout from "../../components/layout"
import AboutComponent from "../../components/pages/main/about"
import BannerComponent from "../../components/pages/features/banner"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import FeaturesComponent from "../../components/pages/main/landing-features"
import FeaturesGridComponent from "../../components/pages/features/grid"
import Discount from "../../components/pages/main/discount"
import Breadcrumb from '../../components/controls/breadcrumbs';

const ClientsFeaturePage = (props) => {
  const crumbs = [
    { name: 'Главная', url: '/'},
    { name: 'Возможности', url: '/feature'},
    { name: 'Учет абонементов', url: ''}
  ];
  return (
    <Layout headerColor={"#03C4A4"} page={"subscriptions"}>
      <SEO
        title="Программа учета абонементов в CRM-системе Параплан"
        keywords={[`учет абонементов`, `продажи абонементов`, `список абонементов`]}
        description="Программа учета абонементов в CRM Параплан автоматизирует работу с абонементами в детских центрах, спортивных, языковых и творческих школах, салонах красоты."
        url={"https://paraplancrm.ru" + props.location.pathname}
      />
      <HeroFeaturePage
        name={"subscriptions"}
        title='Создавайте абонементы'
        description='Наши инструменты позволят быстро провести продажу и переключить внимание администратора на клиента.'
        color='#03C4A4'
        btnDark={true} // Удалить после окончания акции бесплатное лето
        image={require("../../images/cover/subscriptions.png")} />
      <Breadcrumb crumbs={ crumbs } arrowcolor={"green"}/>
      <AboutComponent
        title='Наглядность <br/> в деле'
        description='Интуитивно понятный интерфейс в программе учета посещений, записей и абонементов позволяет сотрудникам быстрее получать доступ к актуальным абонементам и быстро проводить сделки.'
        image={require("../../images/screenshot/subscription/screenshot_04@2x.png")}
        imageWidth={587}
        imageHeight={468}
        bgSize={"position-right"}
        reverse={true}
        color='#E65D49' />
      <Discount />
      <AboutComponent
        title='Готовь сани <br/> летом'
        description='Вы заранее задаете тарифные сетки, а система ведет бухгалтерию и отображает результаты продаж.'
        image={require("../../images/screenshot/subscription/screenshot_05@2x.png")}
        imageWidth={670}
        imageHeight={321}
        bgSize={"small"}
        color='#319DED' />
      <AboutComponent
        title='Гибкость <br/> настройки'
        description='Вы можете задать любой тип абонемента, будь то количество посещений или период действия. Вопрос как вести учет абонементов детского или образовательного центра, спортивного клуба, фитнес-центра, танцевальной студии, творческой мастерской полностью решает Параплан CRM.'
        image={require("../../images/screenshot/subscription/screenshot_03@2x.png")}
        imageWidth={670}
        imageHeight={427}
        reverse={true}
        color='#FFC800' />
      <BannerComponent author={"Савр Мучкаев, детский центр «Азбука»"}>
        Не всегда понятно, какая тактика сработает с клиентами, а какая нет. Но с Параплан мы смогли найти свой
        подход к каждому клиенту, который удвоил наш доход и посещаемость центра.
      </BannerComponent>
      <FeaturesGridComponent
        title={"Охватите все возможности"}
        subtitle={"Система Параплан обеспечит вас инструментами для быстрого роста бизнеса."}
        features={[
          {
            name: "Списание занятий",
            text: "Преподаватель отмечает ученика, а с абонемента автоматически списывается занятие.",
            image: require("../../images/icons/subscription_attendance.svg"),
          },
          {
            name: "Истекающие абонементы",
            text: "Главный экран покажет вам истекающие абонементы и напомнит оформить новый.",
            image: require("../../images/icons/subscription_expiring.svg"),
          },
          {
            name: "Пропущенные занятия",
            text: "Система позволяет сделать возврат средств за пропущенные занятия или использовать их для оплаты следующего абонемента.",
            image: require("../../images/icons/subscription_refund.svg"),
          },
          {
            name: "Оценка эффективности",
            text: "Система рассчитает, какой абонемент продается лучше всего, а вы сможете направить внимание отдела продаж на выгодные сделки.",
            image: require("../../images/icons/subscription_analytics.svg"),
          },
        ]}
      />
      <FeaturesComponent title="Дополнительные возможности" />
      <ContactFormComponent />
    </Layout>
  )
}

export default ClientsFeaturePage